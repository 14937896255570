// @flow
import type { Saga } from 'redux-saga';
import { spawn, put, all } from 'redux-saga/effects';

import { sagasCreated } from './actions';

export default function* containerFlow(): Saga<void> {
  yield all([
    spawn(require('./../network_services/sagas').default),
    spawn(require('./../inventory/sagas').default),
    spawn(require('./../inventory/search/sagas').default),
    spawn(require('./../inventory/export/sagas').default),
    spawn(require('./../cloud_resources/sagas').default),
    spawn(require('./../configurations/sagas').default),
    spawn(require('./../dashboard/sagas').default),
    spawn(require('./../restful_apis/sagas').default),
    spawn(require('./../policy_violations/sagas').default),
    spawn(require('./../policy_violations/comments/sagas').default),
    spawn(require('./../policy_rules/sagas').default),
    spawn(require('./../events/sagas').default),
    spawn(require('./../web_applications/sagas').default),
    spawn(require('./../policies/sagas').default),
    spawn(require('./../asset_groups/sagas').default),
    spawn(require('./../hack_extract/sagas').default),
    spawn(require('./../shared_links/sagas').default),
    spawn(require('./../detect_inject/sagas').default),
    spawn(require('./../atlas/sagas/saga').saga),
    spawn(require('./../atlas/sagas/search').saga),
    spawn(require('@dt/billing-info').billingSaga),
    spawn(require('./../mobile_app_upload/sagas').default),
    spawn(require('./../sagas/apiKeys').default),
    spawn(require('./../sagas/apps').default),
    spawn(require('./../sagas/jiraIntegrationConfig').default),
  ]);

  yield put(sagasCreated());
}
